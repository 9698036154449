import React, { useEffect } from 'react'
import { useMediaQuery } from '@react-hook/media-query'
import styled from '@emotion/styled'
import { HeadingContainer } from '../components/common/typography'
import AppLayout from '../components/layouts/appLayout'
import { MOBILE_DEVICE } from '../components/common/breakpoints'
import SEO from '../components/seo'
import { TwitterTimelineEmbed, TwitterFollowButton } from 'react-twitter-embed'
import bp from '../components/common/breakpoints'
import { BLACK_COLOR, PRIMARY_COLOR, WHITE_COLOR } from '../components/common/color'
import { Link } from 'gatsby'
import BackgroundPattern from '../images/assets/backgroundPattern2.inline.svg'
import { motion, useAnimation } from 'framer-motion'
import { useInView } from "react-intersection-observer";

const Container = styled(motion.div)`
  display: grid;
  grid-template-columns: repeat(3,auto-fill);
  column-gap: 3rem;
  row-gap: 2rem;
  width: 100%;
  margin: 0 auto;
  padding-bottom: 8rem;
  box-sizing: border-box;
  justify-content: center;
  ${bp[3]} {
    grid-template-columns: repeat(3,1fr);
  }
`

const Iframe = styled.iframe`
  display: block;
  width: 100%;
  overflow: hidden;
  border: none;
  box-sizing: border-box;
`
const TwitterDiv = styled.div`
  display: block;
  overflow: hidden;
  border: none;
  box-sizing: border-box;
  padding-bottom: 2rem;
`

const FollowButton = styled.div`
  display: flex;
  justify-content: left;
  height: 100%;
`
const TwitterContainer = styled(motion.div)`
  width: 100%;
`
const SpotifyContainer = styled(motion.div)`
  width: 100%;
  display: flex;
  gap: 1rem;
  flex-direction: column; 
`
const TwitterHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: center;
  width: 100%;
  margin-bottom: 1rem;
  ${bp[2]} {
    justify-content: space-between;
    flex-direction: row;
  }
`
const HeaderTitle = styled.p`
    font-size: 2rem;
    margin: 0;
    padding: 0;
    font-family: 'Manrope';
    font-weight: 600;
`

const BlogContainer = styled(motion.div)`
  width: 100%;
`
const StyledDiv = styled.div`
  background: ${BLACK_COLOR};
  border-radius: 4px;
  padding: 2rem;
  position: relative;
  overflow: hidden;
`
const StyledContent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  gap: 1rem;
  flex-direction: column; 
`
const StyledText = styled.div`
  color: ${WHITE_COLOR};
  font-size: 3rem;
  font-family: 'Manrope';
  font-weight: bold;
`
const StyledP = styled.p`
  color: ${WHITE_COLOR};
  font-size: 2rem;
  font-family: 'Manrope';
  opacity: 0.6;
`
const LinkWrapper = styled(Link)`
  z-index: 10;
  width: 100%;
  display: flex;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  height: 56px;
  border: 2px solid ${WHITE_COLOR};
  font-weight: 600;
  color: ${WHITE_COLOR};
  padding: 0px 16px;
  letter-spacing: 0.115em;
  text-transform: uppercase;
  font-family: 'Source Code Pro' !important;
  font-size: 12px;
  cursor: pointer;
  ${bp[2]} {
    width: fit-content;
    height: 40px;
  }
  :hover {
    color: ${PRIMARY_COLOR};
  }
`
const PatterContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  widht: 100%;
  opacity: 0.2;
  overflow: hidden;
`
const CardAnimate = {
  offscreen: { x: -10, opacity: 0 },
  onscreen: {
    x: 0, opacity: 1,
    transition: {
      type: "spring",
      duration: 2
    }
  }
}

const CommunityPage = () => {
  const controls = useAnimation();
  const [ref, inView] = useInView();

  useEffect(() => {
    if (inView) {
      controls.start("onscreen");
    }
  }, [controls, inView]);

  const matchMobile = useMediaQuery(`(min-width: ${MOBILE_DEVICE}px)`)
  return (
    <AppLayout>
      <SEO title="Community" canonical="Community" />
      <HeadingContainer
        icon={'arrow-left'}
        title="Community"
      ></HeadingContainer>
      <Container initial={"offscreen"} ref={ref} animate={controls} transition={{ staggerChildren: 0.3 }}>
        <SpotifyContainer variants={CardAnimate}>
          <HeaderTitle>Listen to the Podcast</HeaderTitle>
          <Iframe
            style={{ borderRadius: "4px", overflow: "hidden" }}
            src="https://open.spotify.com/embed/show/6DlG7kbCWM5rvVm4wQ10yA?utm_source=generator"
            width="100%"
            height="100%"
            frameBorder="0"
            allowfullscreen=""
            allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
          ></Iframe>
        </SpotifyContainer>
        <BlogContainer variants={CardAnimate} >
          <HeaderTitle>The developers blog</HeaderTitle>
          <StyledDiv>
            <StyledContent>
              <StyledText>Looking for wisdom?</StyledText>
              <StyledP>Take a look at the insights of alio developers and experts</StyledP>
              <LinkWrapper to={'/blog'}>Developers blog</LinkWrapper>
            </StyledContent>
            <PatterContainer>
              <BackgroundPattern style={{ zIndex: '-10' }} />
            </PatterContainer>
          </StyledDiv>
        </BlogContainer>
        <TwitterContainer variants={CardAnimate}>
          <TwitterHeader>
            <HeaderTitle>Latest Tweets</HeaderTitle>
            <FollowButton>
              <TwitterFollowButton
                screenName="alio_it"
                options={{ size: 'large' }}
              />
            </FollowButton>
          </TwitterHeader>
          <TwitterDiv>
            <TwitterTimelineEmbed
              sourceType="profile"
              screenName="alio_it"
              options={{
                height: 500,
                id: `profile:https://twitter.com/alio_it`,
              }}
              noScrollbar
            />
          </TwitterDiv>
        </TwitterContainer>
      </Container>
    </AppLayout>
  )
}

export default CommunityPage
